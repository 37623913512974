var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', [_c('tr', [!_vm.groupLength ? _c('td', {
    attrs: {
      "colspan": "7",
      "align": "center"
    }
  }, [_vm._v(" No records found. ")]) : _vm._e()]), _vm._l(_vm.groupCensusList.records, function (employee, index) {
    return _c('tr', {
      key: index,
      class: "group-status-".concat(employee.approval_status.toLowerCase())
    }, [_c('td', {
      staticClass: "text-capitalize"
    }, [_c('b', [_vm._v("Group Name:")]), _vm._v(" " + _vm._s(employee.group_name)), _c('br'), _c('b', [_vm._v("Contact Name: ")]), _vm._v(" " + _vm._s(employee.group_contact_name)), _c('br'), _c('b', [_vm._v("Contact Phone: ")]), _vm._v(" " + _vm._s(_vm._f("us_phone")(employee.group_contact_phone))), _c('br'), _c('b', [_vm._v("Contact Email:")]), _vm._v(" " + _vm._s(employee.group_contact_email)), _c('br')]), _c('td', {
      staticClass: "text-capitalize"
    }, [_c('b', [_vm._v("Group Address:")]), _vm._v(" " + _vm._s(employee.group_address)), _c('br'), _c('b', [_vm._v("Group Start Date: ")]), _vm._v(_vm._s(employee.group_requested_start_date)), _c('br'), _vm._m(1, true), _vm._v(" " + _vm._s(employee.primary_count)), _c('br'), _vm._m(2, true), _vm._v(" " + _vm._s(employee.dependent_count)), _c('br'), _c('b', [_vm._v("Group Industry:")]), _vm._v(" " + _vm._s(employee.group_industry_title)), _c('br')]), _c('td', _vm._l(employee.group_plans, function (plans, index) {
      return _c('ul', {
        key: index
      }, [_c('li', [_vm._v(_vm._s(plans.plan_name))])]);
    }), 0), _c('td', {
      class: "status-".concat(employee.approval_status.toLowerCase())
    }, [_vm._v(" " + _vm._s(employee.approval_status) + " ")]), _c('td', [_c('custom-button', {
      staticClass: "group-action-icons",
      attrs: {
        "title": "View Group Quote",
        "iconClass": "fas fa-user-friends"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openGroupDetailModel(employee);
        }
      }
    }), _c('custom-button', {
      staticClass: "group-action-icons",
      attrs: {
        "title": "Download Group Quote",
        "iconClass": "fas fa-download"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.downloadGroupXls(employee);
        }
      }
    }), _c('custom-button', {
      staticClass: "group-action-icons",
      attrs: {
        "title": "Group Registration",
        "iconClass": "fas fa-link"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.routeToGroupRegistration(employee);
        }
      }
    }), employee.approval_status === 'APPROVED' && employee.approved_file_url ? _c('custom-button', {
      staticClass: "group-action-icons",
      attrs: {
        "title": "Download Group Quote Approved File",
        "iconClass": "fas fa-file-pdf"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.downloadApprovedFiles(employee);
        }
      }
    }) : _vm._e()], 1)]);
  }), _vm.groupCensusList.length < 0 ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "7",
      "align": "center"
    }
  }, [_vm._v("No records found.")])]) : _vm._e()], 2)]), _c('div', [_c('group-detail-model', {
    attrs: {
      "employeeInfo": _vm.employeeInfo
    }
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Contact")]), _c('th', [_vm._v("Group Details")]), _c('th', [_vm._v("Group Plans")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Action")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b', [_vm._v("Number of Primary Applicant"), _c('span', {
    staticStyle: {
      "text-transform": "lowercase"
    }
  }, [_vm._v("(s)")]), _vm._v(":")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b', [_vm._v("Number of Dependent"), _c('span', {
    staticStyle: {
      "text-transform": "lowercase"
    }
  }, [_vm._v("(s)")]), _vm._v(":")]);
}]

export { render, staticRenderFns }