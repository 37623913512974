<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Contact</th>
          <th>Group Details</th>
          <th>Group Plans</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-if="!groupLength" colspan="7" align="center">
            No records found.
          </td>
        </tr>
        <tr v-for="(employee, index) in groupCensusList.records"
            :class="`group-status-${employee.approval_status.toLowerCase()}`"
            :key="index">
          <td class="text-capitalize">
            <b>Group Name:</b> {{ employee.group_name }}<br />
            <b>Contact Name: </b> {{ employee.group_contact_name }}<br />
            <b>Contact Phone: </b> {{ employee.group_contact_phone | us_phone
            }}<br />
            <b>Contact Email:</b> {{ employee.group_contact_email }}<br />
          </td>
          <td class="text-capitalize">
            <b>Group Address:</b> {{ employee.group_address }}<br />
            <b>Group Start Date: </b>{{ employee.group_requested_start_date}}<br />
            <b>Number of Primary Applicant<span style="text-transform: lowercase;">(s)</span>:</b> {{ employee.primary_count}}<br />
            <b>Number of Dependent<span style="text-transform: lowercase;">(s)</span>:</b> {{ employee.dependent_count }}<br />
            <b>Group Industry:</b> {{ employee.group_industry_title }}<br />
          </td>
          <td>
            <ul v-for="(plans, index) in employee.group_plans" :key="index">
              <li>{{ plans.plan_name }}</li>
            </ul>
          </td>
          <td  :class="`status-${employee.approval_status.toLowerCase()}`">
            {{ employee.approval_status }}
          </td>
          <td>
            <custom-button
              class="group-action-icons"
              title="View Group Quote"
              @onClicked="openGroupDetailModel(employee)"
              iconClass="fas fa-user-friends"
            />
            <custom-button
              @onClicked="downloadGroupXls(employee)"
              class="group-action-icons"
              title="Download Group Quote"
              iconClass="fas fa-download"
            />
            <custom-button
                @onClicked="routeToGroupRegistration(employee)"
                class="group-action-icons"
                title="Group Registration"
                iconClass="fas fa-link"
            />
            <custom-button
                v-if="(employee.approval_status === 'APPROVED' && employee.approved_file_url)"
                @onClicked="downloadApprovedFiles(employee)"
                class="group-action-icons"
                title="Download Group Quote Approved File"
                iconClass="fas fa-file-pdf"
            />
          </td>
        </tr>
        <tr v-if="groupCensusList.length < 0">
          <td colspan="7" align="center">No records found.</td>
        </tr>
      </tbody>
    </table>
    <div>
      <group-detail-model :employeeInfo="employeeInfo" />
    </div>
  </div>
</template>

<script>
import GroupListDetailModal from "../modal/GroupListDetailModal";
export default {
  name: "GroupCensusTable",
  components: {
    "group-detail-model": GroupListDetailModal,
  },
  props: {
    groupCensusList: {
      type: Object,
    },
  },
  computed: {
    groupLength() {
      return this.groupCensusList?.records?.length > 0;
    },
    repInfo () {
      return this.$store.getters.rep
    }
  },
  data() {
    return {
      employeeInfo: {},
    };
  },
  methods: {
    openGroupDetailModel(employee) {
      let app = this;
      app.employeeInfo = {};
      app.employeeInfo = employee;
      app.$bvModal.show("group-detail-view");
    },
    downloadGroupXls(xls) {
      window.open(xls?.file_url, "_blank");
    },
    downloadApprovedFiles(approveFile) {
      window.open(approveFile?.approved_file_url, "_blank");
    },
    routeToGroupRegistration (employee) {
      let app = this
      let groupCensusId = employee.id ? employee.id: null
      const groupRegistrationLink =
          app.$endpoint.GROUP_APP_URL +
          `/registration?repCode=${app.repInfo.code}&groupCensusId=${groupCensusId}`;
      window.open(groupRegistrationLink, "_blank");
    },
  },
};
</script>

<style scoped>
</style>