<template>
  <div>
    <div class="group-filter-container form-wrap">
      <div class="row">
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title text-left">Group Name</label>
          <input
              type="text"
              v-model="searchFilter.group_name"
              class="input-field"
              placeholder="Group Name"
          />
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title text-left">Contact Name</label>
          <input
              type="text"
              v-model="searchFilter.group_contact_name"
              class="input-field"
              placeholder="Contact Name"
          />
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title text-left">Contact Email</label>
          <input
              type="text"
              v-model="searchFilter.group_contact_email"
              class="input-field"
              placeholder="Contact Email"
          />
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title text-left">Contact Phone</label>
          <input
              type="Number"
              v-model="searchFilter.group_contact_phone"
              class="input-field"
              placeholder="Contact Phone"
          />
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title mb-1">Status </label>
          <select v-model="searchFilter.status"
                  class="custom-select">
            <option value='' disabled> Select Status</option>
            <option  v-for="(status, index) in groupStatusList"
                     :value="status.value"
                     :key="index">{{status.text}}</option>
          </select>
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title">Group Plans</label>
          <multiselect
              style="width: 100% !important; margin-top: 6px !important; margin-left: 0 !important;"
              v-model="selectedGroupPlans"
              placeholder="Select Group Plans"
              :options="groupPlanList"
              label="text"
              :searchable="true"
              track-by="value"
              :multiple="true"
              :taggable="true"
              @input="updateGroupPlans"
          >
          </multiselect>
        </div>
        <div class="col-xxl-3 col-lg-3 col-md-6 mb-3">
          <label class="label-title">Downline List</label>
          <multiselect
              style="width: 100% !important; margin-top: 6px !important; margin-left: 0 !important;"
              v-model='selectedDownlines'
              placeholder="Select Downline List"
              :options="downlineList"
              label="text"
              :searchable="true"
              track-by="value"
              :multiple="true"
              :taggable="true"
              @input="updateDownlineList"
          >
          </multiselect>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button class="group-clear-btn" @click="clearSearch">Clear</button>
        <button class="group-univ-btn mt-0" @click="setFilters">Search</button>
      </div>
    </div>
  </div>
</template>

<script>
import AuthLessApi from "../../../resource/AuthLessApi";
const filterData = {
  group_name: '',
  group_contact_name: '',
  group_contact_email: '',
  group_contact_phone: '',
  status: '',
  group_plans: [],
  downline_list: []
};
export default {
  name: "GroupCensusFilter",
  props: {
    repId: {
      type: Number
    }
  },
  data () {
    return {
      searchFilter: {
        group_name: '',
        group_contact_name: '',
        group_contact_email: '',
        group_contact_phone: '',
        status: '',
        group_plans: [],
        downline_list: [],
      },
      groupPlanList: [],
      downlineList: [],
      selectedDownlines: [],
      selectedGroupPlans: [],
      groupStatusList: [
        {text: 'Declined', value: 'DECLINED'},
        {text: 'Approved', value: 'APPROVED'},
        {text: 'Pending', value: 'PENDING'}
      ]
    }
  },
  mounted () {
    let app = this
    app.getPlanList();
    app.getDownlineList();
  },
  methods: {
    setFilters () {
      let app = this
      app.$emit('onSearch', app.searchFilter);
    },
    clearSearch() {
      let app = this
      app.selectedDownlines = [],
      app.selectedGroupPlans = [],
      app.searchFilter = { ...filterData }
      app.$emit("onClearSearch");
    },
    updateDownlineList (selectedOptions) {
      let app = this
      app.selectedDownlines = selectedOptions;
      app.searchFilter.downline_list = selectedOptions.map(option => option.value);
    },
    updateGroupPlans (selectedOptions) {
      let app = this
      app.selectedGroupPlans = selectedOptions;
      app.searchFilter.group_plans = selectedOptions.map(option => option.value);
    },
    getPlanList () {
      let app = this
      const url = `v2/group-census/plans?rep_id=${app.repId}`
      let loader = app.$loading.show();
      AuthLessApi.get(url)
          .then((response) => {
            app.groupPlanList = []
            let planListsResponse = response.data.data
            planListsResponse.forEach ((plans) => {
              let Obj = {
                text: plans.web_display_name,
                value: plans.pid
              }
              app.groupPlanList.push(Obj)
            })
            loader.hide();
          }).finally( () => {
        app.$loading.hide();
      })
    },
    getDownlineList () {
      let app = this
      const url = `v2/group-census/group-census-downline-reps?rep_id=${app.repId}`
      let loader = app.$loading.show();
      AuthLessApi.get(url)
          .then((response) => {
            app.downlineList = []
            let downlineListsResponse = response.data.data
            downlineListsResponse.forEach ((downline) => {
              let Obj = {
                text: downline.name,
                value: downline.id
              }
              app.downlineList.push(Obj)
            })
            loader.hide();
          }).finally( () => {
        app.$loading.hide();
      })
    }
  }
}
</script>

<style scoped>

</style>