<template>
  <div class="page-wrapper">
    <div class="container-fluid p-0">
      <breadcrumb :items="breadcrumbItems" />
      <div class="top-title">
        <div class="page-title">
          <h1>View Group Quotes</h1>
        </div>
        <div class="submit-btn-container d-flex justify-content-end">
          <button type="submit" @click="routeToGroupCensus"
                  class="group-univ-btn text-center"> Add Group Quote
          </button>
        </div>
        <div>
          <group-census-filter  @onSearch="filterData"
                                :repId="repId"
                                @onClearSearch="clearData"/>
        </div>
        <div class="employeeData-wrap">
          <div class="top-pagination">
            <pagination :rows.sync="rows"  :filters="filterQuery" />
          </div>
          <div>
            <group-census-table :groupCensusList="rows" />
          </div>
          <div>
            <pagination  :rows.sync="rows" :filters="filterQuery" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GroupCensusTable from "./GroupCensusTable";
import AuthLessApi from "../../../resource/AuthLessApi";
import GroupCensusFilter from "./GroupCensusFilter";
import Helper from "@/resource/Helper";

const filterData = {
  group_name: '',
  group_contact_name: '',
  group_contact_email: '',
  group_contact_phone: '',
  status: '',
  group_plans: ''
};
export default {
  name: "GroupCensusList",
  components: {GroupCensusTable, GroupCensusFilter},
  data () {
    return {
      rows: [],
      breadcrumbItems: [{ title: "View Group Quote", active: true }],
      filterQuery: { ...filterData }
    }
  },
  mounted () {
    let app = this
    app.getGroupCensusList(app.filterQuery)
  },
  computed: {
    repId () {
      return this.$store.getters.repId
    }
  },
  methods: {
    clearData() {
      let app = this
      app.filterQuery = { ...filterData };
      app.getGroupCensusList(app.filterQuery);
    },
    filterData(filters) {
      let app = this
      app.filterQuery = filters;
      app.getGroupCensusList(app.filterQuery);
    },
    getGroupCensusList(filters = []) {
      let app = this
      let url;
      let getParams = {
        'rep_id': app.$store.getters.repId,
        ...filters
      }
      let filterUrl = Helper.objToUrlParams(getParams);
      url = `v2/group-census?${filterUrl}`
      let loader = this.$loading.show();
      AuthLessApi.get(url)
          .then((res) => {
            app.rows = res.data
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            loader.hide();
          });
    },
    routeToGroupCensus () {
      window.open('/group-verify', '_blank' )
    }
  },
}
</script>

<style scoped>

</style>