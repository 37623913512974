var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid p-0"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    staticClass: "top-title"
  }, [_vm._m(0), _c('div', {
    staticClass: "submit-btn-container d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "group-univ-btn text-center",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.routeToGroupCensus
    }
  }, [_vm._v(" Add Group Quote ")])]), _c('div', [_c('group-census-filter', {
    attrs: {
      "repId": _vm.repId
    },
    on: {
      "onSearch": _vm.filterData,
      "onClearSearch": _vm.clearData
    }
  })], 1), _c('div', {
    staticClass: "employeeData-wrap"
  }, [_c('div', {
    staticClass: "top-pagination"
  }, [_c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "filters": _vm.filterQuery
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1), _c('div', [_c('group-census-table', {
    attrs: {
      "groupCensusList": _vm.rows
    }
  })], 1), _c('div', [_c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "filters": _vm.filterQuery
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1)])])], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("View Group Quotes")])]);
}]

export { render, staticRenderFns }