<template>
  <b-modal id="group-detail-view" centered size="xl" hide-footer @show="renderModal">
    <div class="title-text text-center mb-2">
      <h5><strong>View Group Quotes</strong></h5>
    </div>
    <div>
      <div class="group-detail-h4 pt-0">Group Info</div>
      <div class="m-0 pt-0 pb-2 group-info-container">
        <div class="row group-info-detail view-grp-info">
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
            <strong>Group Name:</strong>
            <div class="grp-info-txt">{{ employeeInfo.group_name }}</div>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
            <strong>Group Address:</strong>
            <div class="grp-info-txt">{{ employeeInfo.group_address }}</div>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
            <strong>Requested Start Date:</strong>
            <div class="grp-info-txt">{{ employeeInfo.group_requested_start_date }}</div>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
            <strong>Group Industry:</strong>
            <div class="grp-info-txt">{{ employeeInfo.group_industry }}</div>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
            <strong>Group Contact Name:</strong>
            <div class="grp-info-txt">{{ employeeInfo.group_contact_name }}</div>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
            <strong>Group Contact Phone:</strong>
            <div class="grp-info-txt">{{ employeeInfo.group_contact_phone | us_phone }}</div>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
            <strong>Group Contact Email:</strong>
            <div class="grp-info-txt">{{ employeeInfo.group_contact_email }}</div>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
            <strong>Number of Primary Applicant(s):</strong>
            <div>{{ employeeInfo.primary_count }}</div>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
            <strong>Number of Dependent(s):</strong>
            <div>{{ employeeInfo.dependent_count }}</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="group-detail-h4 pb-0">Submitter Info</div>
      <div class="mt-0 mb-2 p-2 group-info-container">
        <div class="row group-info-detail view-grp-info">
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
            <strong>Submitter Name:</strong>
            <div class="grp-info-txt">{{ employeeInfo.submitter_name }}</div>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
            <strong>Submitter Phone:</strong>
            <div class="grp-info-txt">{{ employeeInfo.submitter_phone | us_phone }}</div>
          </div>
          <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
            <strong>Submitter Email:</strong>
            <div class="grp-info-txt">{{ employeeInfo.submitter_email }}</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="group-detail-h4">Employee Info</div>
      <div class="m-0 p-0 mb-0 group-info-container">
        <div class="grp-quote table-responsive">
          <table class="table">
            <thead>
              <th>S.N</th>
              <th>Primary Full Name</th>
              <th>Full Name</th>
              <th>Plans</th>
              <th>Plans Type</th>
              <!-- <th>Relationship</th> -->
              <th>DOB /Gender</th>
              <th>State/Zip</th>
              <th>Dependent(s)</th>
            </thead>
            <tbody
              v-for="(employee, index) in employeeInfo.group_census_report"
              :key="index"
            >
              <tr>
                <td>
                  {{ index + 1 }}
                </td>
                <td>{{ employee.primary_fullname }}</td>
                <td>{{ employee.first_name }} {{ employee.last_name }}</td>
                <td>{{ employee.plan_info.web_display_name }}</td>
                <td>
                  <div v-if="employee.plan_type == 'IO'">Single</div>
                  <div v-else-if="employee.plan_type == 'IS'">
                    Single + Spouse
                  </div>
                  <div v-else-if="employee.plan_type == 'IC'">
                    Single + Children
                  </div>
                  <div v-else-if="employee.plan_type == 'IF'">
                    Single + Family
                  </div>
                </td>
                <!-- <td>
                  <div v-if="employee.relationship == 'P'">Primary</div>
                  <div v-else-if="employee.relationship == 'S'">Spouse</div>
                  <div v-else-if="employee.relationship == 'D'">Dependent</div>
                </td> -->
                <td>{{ employee.dob }} <br />{{ employee.gender }}</td>
                <td>
                  {{ employee.state_info.name }}<br />
                  {{ employee.zip }}
                </td>

                <td v-if="employee.group_census_dependent.length > 0">
                  <custom-button
                      class="group-action-icons m-0"
                      title="Dependent(s)"
                      @onClicked="showOverlay(employee)" 
                      iconClass="fas fa-people-arrows"
                    />
                </td>
                <td v-else>N/A</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="sideOverlay mt-3" v-if="isOverlayVisible">
          <div class="back-drp w-100">
            <div class="d-flex justify-content-between">
              <div class="group-detail-h4">{{selectedEmployee.group_census_dependent.length > 1 ? 'Dependent(s)' : 'Dependent(s)'}} Info</div>
              <button class="close-button close-dependent" @click="hideOverlay(index)" >
                <img src="@/assets/images/xmark.svg" />
              </button>
            </div>

            <div
              class="mt-2 mb-2 p-2 group-info-container"
              v-for="(dep, index) in selectedEmployee.group_census_dependent"
              :key="index"
            >
              <div class="row group-info-detail">
                <div class="col-xxl- col-xl-3 col-lg-6 col-md-6 mb-2">
                  <strong>First Name:</strong>
                  <div class="grp-info-txt">{{ dep.first_name }}</div>
                </div>
                <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
                  <strong>Last Name:</strong>
                  <div class="grp-info-txt">{{ dep.last_name }}</div>
                </div>
                <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
                  <strong>DOB:</strong>
                  <div class="grp-info-txt">{{ dep.dob }}</div>
                </div>
                <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
                  <strong>Gender:</strong>
                  <div class="grp-info-txt">{{ dep.gender }}</div>
                </div>
                <div class="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2">
                  <strong>Relationship:</strong>
                  <div v-if="dep.relationship == 'S'">Spouse</div>
                  <div v-else-if="dep.relationship == 'C'">Child</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "EditDepInfoModel",
  props: {
    employeeInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOverlayVisible: false,
      selectedEmployee: [],
      selectedIndex: null, // Track index of selected employee
    };
  },
  methods: {
    showOverlay(employee) {
      this.selectedEmployee = employee;
      this.isOverlayVisible = true;
    },
    hideOverlay(index) {
      // Hide the overlay for the selected index
      this.isOverlayVisible = false;
      // Clear selectedEmployee only if the same index is selected
      if (index === this.selectedIndex) {
        this.selectedEmployee = null;
        this.selectedIndex = null;
      }
    },
    renderModal () {
      let app = this
      app.isOverlayVisible = false;
    }
  },
};
</script>

<style >
#group-detail-view .modal-body table th {
  padding: 1rem;
  background: #dcedfc;
  border: 1px solid #ccc;
  color: #254050;
  position: sticky;
  top: -1px;
  z-index: 2; 
}
#group-detail-view .modal-body td {
  padding: 1rem;
  border: 1px solid #ccc;
  color: #254050;
}
.view-grp-info {
  box-shadow: none;
}
.grp-quote {
  max-height: 290px;
  overflow: scroll;
  margin: 0 0.5rem;
  border-radius: 4px;
}
.back-drp {
  /* background: #00000022; */
}
.sideOverlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 98%;
  background-color: #fff;
  color: #000;
  padding: 20px;
  box-shadow: -2px 0 5px rgb(0 0 0 / 18%);
  z-index: 1050; /* Make sure it's above the modal */
  overflow-y: auto;
  border-radius: 0 0px 6px 0;
}
.group-detail-h4{
  color: #00b6ff;
  font-weight: 600;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  transform: translateX(100%);
  opacity: 0;
}
.close-button {
  border: none;
  padding: 0.5rem 0.8rem;
  background: linear-gradient(#0cf, #1597b8 49%, #0567bc);
  color: #fff;
  border-radius: 6px;
}
.dependent-btn {
  border: none;
  padding: 0.5rem 0.8rem;
  background: linear-gradient(#0cf, #1597b8 49%, #0567bc);
  border-radius: 6px;
  color: #fff;
  outline: none;
}
.grp-info-txt{
  word-break: break-all;
}
.close-dependent{
  background: transparent !important;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  max-height: 40px;
}
.close-dependent img{
  min-width: 12px;
}

</style>