var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "group-detail-view",
      "centered": "",
      "size": "xl",
      "hide-footer": ""
    },
    on: {
      "show": _vm.renderModal
    }
  }, [_c('div', {
    staticClass: "title-text text-center mb-2"
  }, [_c('h5', [_c('strong', [_vm._v("View Group Quotes")])])]), _c('div', [_c('div', {
    staticClass: "group-detail-h4 pt-0"
  }, [_vm._v("Group Info")]), _c('div', {
    staticClass: "m-0 pt-0 pb-2 group-info-container"
  }, [_c('div', {
    staticClass: "row group-info-detail view-grp-info"
  }, [_c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
  }, [_c('strong', [_vm._v("Group Name:")]), _c('div', {
    staticClass: "grp-info-txt"
  }, [_vm._v(_vm._s(_vm.employeeInfo.group_name))])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
  }, [_c('strong', [_vm._v("Group Address:")]), _c('div', {
    staticClass: "grp-info-txt"
  }, [_vm._v(_vm._s(_vm.employeeInfo.group_address))])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
  }, [_c('strong', [_vm._v("Requested Start Date:")]), _c('div', {
    staticClass: "grp-info-txt"
  }, [_vm._v(_vm._s(_vm.employeeInfo.group_requested_start_date))])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
  }, [_c('strong', [_vm._v("Group Industry:")]), _c('div', {
    staticClass: "grp-info-txt"
  }, [_vm._v(_vm._s(_vm.employeeInfo.group_industry))])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
  }, [_c('strong', [_vm._v("Group Contact Name:")]), _c('div', {
    staticClass: "grp-info-txt"
  }, [_vm._v(_vm._s(_vm.employeeInfo.group_contact_name))])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
  }, [_c('strong', [_vm._v("Group Contact Phone:")]), _c('div', {
    staticClass: "grp-info-txt"
  }, [_vm._v(_vm._s(_vm._f("us_phone")(_vm.employeeInfo.group_contact_phone)))])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
  }, [_c('strong', [_vm._v("Group Contact Email:")]), _c('div', {
    staticClass: "grp-info-txt"
  }, [_vm._v(_vm._s(_vm.employeeInfo.group_contact_email))])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
  }, [_c('strong', [_vm._v("Number of Primary Applicant(s):")]), _c('div', [_vm._v(_vm._s(_vm.employeeInfo.primary_count))])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
  }, [_c('strong', [_vm._v("Number of Dependent(s):")]), _c('div', [_vm._v(_vm._s(_vm.employeeInfo.dependent_count))])])])])]), _c('div', [_c('div', {
    staticClass: "group-detail-h4 pb-0"
  }, [_vm._v("Submitter Info")]), _c('div', {
    staticClass: "mt-0 mb-2 p-2 group-info-container"
  }, [_c('div', {
    staticClass: "row group-info-detail view-grp-info"
  }, [_c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
  }, [_c('strong', [_vm._v("Submitter Name:")]), _c('div', {
    staticClass: "grp-info-txt"
  }, [_vm._v(_vm._s(_vm.employeeInfo.submitter_name))])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
  }, [_c('strong', [_vm._v("Submitter Phone:")]), _c('div', {
    staticClass: "grp-info-txt"
  }, [_vm._v(_vm._s(_vm._f("us_phone")(_vm.employeeInfo.submitter_phone)))])]), _c('div', {
    staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
  }, [_c('strong', [_vm._v("Submitter Email:")]), _c('div', {
    staticClass: "grp-info-txt"
  }, [_vm._v(_vm._s(_vm.employeeInfo.submitter_email))])])])])]), _c('div', [_c('div', {
    staticClass: "group-detail-h4"
  }, [_vm._v("Employee Info")]), _c('div', {
    staticClass: "m-0 p-0 mb-0 group-info-container"
  }, [_c('div', {
    staticClass: "grp-quote table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('th', [_vm._v("S.N")]), _c('th', [_vm._v("Primary Full Name")]), _c('th', [_vm._v("Full Name")]), _c('th', [_vm._v("Plans")]), _c('th', [_vm._v("Plans Type")]), _c('th', [_vm._v("DOB /Gender")]), _c('th', [_vm._v("State/Zip")]), _c('th', [_vm._v("Dependent(s)")])]), _vm._l(_vm.employeeInfo.group_census_report, function (employee, index) {
    return _c('tbody', {
      key: index
    }, [_c('tr', [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm._v(_vm._s(employee.primary_fullname))]), _c('td', [_vm._v(_vm._s(employee.first_name) + " " + _vm._s(employee.last_name))]), _c('td', [_vm._v(_vm._s(employee.plan_info.web_display_name))]), _c('td', [employee.plan_type == 'IO' ? _c('div', [_vm._v("Single")]) : employee.plan_type == 'IS' ? _c('div', [_vm._v(" Single + Spouse ")]) : employee.plan_type == 'IC' ? _c('div', [_vm._v(" Single + Children ")]) : employee.plan_type == 'IF' ? _c('div', [_vm._v(" Single + Family ")]) : _vm._e()]), _c('td', [_vm._v(_vm._s(employee.dob) + " "), _c('br'), _vm._v(_vm._s(employee.gender))]), _c('td', [_vm._v(" " + _vm._s(employee.state_info.name)), _c('br'), _vm._v(" " + _vm._s(employee.zip) + " ")]), employee.group_census_dependent.length > 0 ? _c('td', [_c('custom-button', {
      staticClass: "group-action-icons m-0",
      attrs: {
        "title": "Dependent(s)",
        "iconClass": "fas fa-people-arrows"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.showOverlay(employee);
        }
      }
    })], 1) : _c('td', [_vm._v("N/A")])])]);
  })], 2)]), _vm.isOverlayVisible ? _c('div', {
    staticClass: "sideOverlay mt-3"
  }, [_c('div', {
    staticClass: "back-drp w-100"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "group-detail-h4"
  }, [_vm._v(_vm._s(_vm.selectedEmployee.group_census_dependent.length > 1 ? 'Dependent(s)' : 'Dependent(s)') + " Info")]), _c('button', {
    staticClass: "close-button close-dependent",
    on: {
      "click": function click($event) {
        return _vm.hideOverlay(_vm.index);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/xmark.svg")
    }
  })])]), _vm._l(_vm.selectedEmployee.group_census_dependent, function (dep, index) {
    return _c('div', {
      key: index,
      staticClass: "mt-2 mb-2 p-2 group-info-container"
    }, [_c('div', {
      staticClass: "row group-info-detail"
    }, [_c('div', {
      staticClass: "col-xxl- col-xl-3 col-lg-6 col-md-6 mb-2"
    }, [_c('strong', [_vm._v("First Name:")]), _c('div', {
      staticClass: "grp-info-txt"
    }, [_vm._v(_vm._s(dep.first_name))])]), _c('div', {
      staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
    }, [_c('strong', [_vm._v("Last Name:")]), _c('div', {
      staticClass: "grp-info-txt"
    }, [_vm._v(_vm._s(dep.last_name))])]), _c('div', {
      staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
    }, [_c('strong', [_vm._v("DOB:")]), _c('div', {
      staticClass: "grp-info-txt"
    }, [_vm._v(_vm._s(dep.dob))])]), _c('div', {
      staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
    }, [_c('strong', [_vm._v("Gender:")]), _c('div', {
      staticClass: "grp-info-txt"
    }, [_vm._v(_vm._s(dep.gender))])]), _c('div', {
      staticClass: "col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-2"
    }, [_c('strong', [_vm._v("Relationship:")]), dep.relationship == 'S' ? _c('div', [_vm._v("Spouse")]) : dep.relationship == 'C' ? _c('div', [_vm._v("Child")]) : _vm._e()])])]);
  })], 2)]) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }